import {Controller} from "@hotwired/stimulus"
import Web3 from 'web3'
import * as net from "net";

const web3 = new Web3(
    Web3.givenProvider
);

let eth_address = '';
let signature = '';
let chain_id = 0;
let metamask_found = 0;

export default class extends Controller {
    static targets = [ "loginForm" ]

    async metamask_login() {

        try {
            signature = '';
            eth_address = '';

            if (await init() && await requestAccount()) {

                const jsonResponse = await requestNonce();

                if(jsonResponse.wallet_found && jsonResponse.message_to_sign !== "") {

                    signature = await signNonce(jsonResponse.message_to_sign, eth_address)
                }
            }

            const hiddenInputAddress = generateHiddenInput("eth_address", eth_address);
            const hiddenInputSignature = generateHiddenInput("signature", signature);
            const hiddenInputMetamaskFound = generateHiddenInput("metamask_found", metamask_found);
            const hiddenInputChainId = generateHiddenInput("chain_id", chain_id);

            const form = this.loginFormTarget;

            form.appendChild(hiddenInputAddress);
            form.appendChild(hiddenInputSignature);
            form.appendChild(hiddenInputMetamaskFound);
            form.appendChild(hiddenInputChainId);

            form.submit();

            form.removeChild(hiddenInputAddress);
            form.removeChild(hiddenInputSignature);
            form.appendChild(hiddenInputMetamaskFound);
            form.appendChild(hiddenInputChainId);


        } catch(e) {
            console.log(e)
        }

    }
}

const init = async () => {

    if (typeof window.ethereum !== 'undefined') {

        metamask_found = 1;
        // const NETWORK_ID = await web3.eth.net.getId();
        // const CONTRACT_ADDRESS = configuration.networks[NETWORK_ID].address;
        // const CONTRACT_ABI = configuration.abis;
        // contract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);

        web3.eth.net.getId().then(chainId => {
            chain_id = chainId;
        })

        return true;

    } else {
        metamask_found = 0;
        chain_id = 0;
        return false;
    }
}

const requestAccount = async () => {
    let accounts = await web3.eth.requestAccounts();
    eth_address = accounts[0].toLowerCase();
    return web3.utils.isAddress(eth_address);
}

function generateHiddenInput(parameterName, value) {
    const hiddenInput = document.createElement("input");
    hiddenInput.setAttribute("type", "hidden");
    hiddenInput.setAttribute("name", parameterName);
    hiddenInput.setAttribute("id", parameterName);
    hiddenInput.setAttribute("value", value);

    return hiddenInput;
}

const requestNonce = async () => {

    const params = `../wallets/get_eth_nonce/?eth_address=${eth_address}&metamask_found=${metamask_found}&net_id=${chain_id}`;
    const response = await fetch(params);
    const responseJson = await response.json();

    if (!responseJson) {
        return null;
    }

    return responseJson;
}

const signNonce = async (address, message) => {
    return await ethereum.request({method: 'personal_sign', params: [message, address]});
}